export const light = {
  text: '#404040',
  text_a80: '#404040CC',
  text_a50: '#40404080',
  text_a30: '#4040404D',
  text_a20: '#40404033',
  text_a10: '#4040401A',
  text_light: '#FFF',
  text_dark: '#404040',
  text_credit_banner: '#404040',

  background_sunken: '#E1E1E1',
  background: '#FCFCFC',
  background_rised: '#FFF',
  background_contrast: 'var(--background_sunken)',
  background_lighter: '#FCFCFC',
  background_border: 'transparent',
  background_message: '#FAFAFA',

  backdrop_color: '#00000099',
  dark_overlay: '#00000099',

  button_border_neutral: '#888888',

  card_bg: 'var(--background_rised)',
  card_bg_selected: 'var(--tertiary_20)',
  card_text_selected: 'var(--text)',
  card_border: 'var(--border_sm)',
  card_border_color: 'var(--gray_20)',

  drawer_bg: 'var(--background)',
  drawer_border: 'var(--card_border) var(--tertiary)',

  header_bg: '#212121',
  header_counter_bg: 'var(--gray_90)',
  header_counter_border: 'var(--gray_80)',

  category_heading_text: 'var(--text)',
  category_bar_bg: 'var(--header_bg)',
  category_bar_bg_variant: 'var(--background)',
  category_button_bg: 'var(--gray_90)',
  category_button_text: 'var(--text_light)',
  category_button_bg_selected: 'var(--tertiary_30)',
  category_button_text_selected: 'var(--text)',
  category_button_bg_variant: 'var(--background)',
  category_button_text_variant: 'var(--text)',
  category_button_border_variant: 'var(--gray_50)',
  category_button_bg_selected_variant: 'var(--tertiary_30)',
  category_button_text_selected_variant: 'var(--text)',
  category_button_border_selected_variant: 'var(--text)',
  category_button_bg_expanded_variant: 'var(--text)',
  category_button_text_expanded_variant: 'var(--background)',
  category_button_border_expanded_variant: 'var(--text)',
  category_slider_bg: 'var(--tertiary_30)',

  input_bg: 'var(--background_rised)',
  input_border: 'var(--gray_40)',

  payment_card_icon_selected: '', // original icon color
  payment_logos_filter: '',
  
  img_placeholder_bg: 'var(--primary_a20)',
  img_placeholder_bg_selected: 'var(--background)',
  
  ticket_header_bg: 'var(--gray_10)',
  
  badge_qr_color: 'var(--background)',
  badge_qr_bg: 'var(--text)',
  
  qr_fg_color: 'var(--text)',
  qr_bg_color: 'var(--ticket_header_bg)',

  wifi_text_color: '#1A91E7',

  danger: '#B3261E',
  danger_10: '#FCEBE7',
  danger_20: '#FFEAE5',
  danger_70: '#662B26',

  warning: '#FFDE85',
  warning_20: '#FFFBEF',
  warning_40: '#FFF3D1',
  warning_50: '#FFB88C',
  warning_70: '#A18948',

  gray_05: '#FCFCFC',
  gray_10: '#F7F7F7',
  gray_20: '#EEE',
  gray_30: '#E1E1E1',
  gray_40: '#DDD',
  gray_45: '#D8D8D8',
  gray_50: '#C4C4C4',
  gray_60: '#B9B9B9',
  gray_65: '#A7A7A7',
  gray_70: '#999',
  gray_80: '#767676',
  gray_90: '#696969',

  greenish: '#0A955E',

  point_gradient: 'linear-gradient(68.74deg, #5FB894 -40.86%, #C1EBDA 56.81%)',
  point_gradient_70: 'linear-gradient(302.62deg, #9FE6C6 17.65%, #68CFA5 56.24%, #5FB894 90.2%)',
  point_gradient_40: 'linear-gradient(302.62deg, #5FB894 -141.32%, #C1EBDA -31.9%, #EFF8F4 75.88%)',
  cash_gradient: 'linear-gradient(291.32deg, #FDDF8A -1.95%, #FFF1C9 66.4%)',
  cash_gradient_70: 'linear-gradient(291.32deg, #F8B90C 0%, #FEDE80 100%)',
  cash_gradient_40: 'linear-gradient(21.32deg, #FDDF8A -40.86%, #FFF1C9 56.81%)',
  credit_banner: '#DFFFDF',
  switch_credit_banner: '#DFFFDF',
  switch_loyalty: 'var(--cash_gradient_40)',
  bg_credit_banner: 'rgba(245, 255, 245, 0.4)',
  bg_loyalty_banner: 'rgba(255, 246, 221, 0.4)',
  bg_switch_credit_banner: '#434343',
  bg_switch_loyalty: '#434343',
  border_switch_credit_banner: '#B4E5B4',
  border_switch_loyalty: '#F2E3BB',
  switch_disabled: '#F6F6F6',
  switch_disabled_text: '#404040c7',

  photo_overlay: '0deg, #0003, #000A',
  cover_overlay: '0deg, #E1E1E144, #FCFCFC22',

  border_xs: '1px solid',
  border_sm: '2px solid',
  border_md: '2px solid',

  flow_status_header_bg_color: "#212121",
  flow_status_bg_color: "#FFFFFF",

  square_icon_shadow_color: 'var(--text)',
};