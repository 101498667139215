import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { PopupType } from "../../context/popups.enum";
import { PopupParams } from "../../context/app";
import { AuthContext } from "../../context/auth/AuthContext";
import { PopupContext } from "../../context/PopupContext";
import { isEqual } from "lodash";

const ANIMATION_DURATION = 500;

const keepOpenPopups = [
  PopupType.Checkout,
  PopupType.Splits,
  PopupType.SplitByProduct,
  PopupType.SplitByAmount,
  PopupType.SplitEvenly,
  PopupType.TipsSmall,
  PopupType.TipsBig,
  PopupType.PaymentDetail,
  PopupType.PaymentMethods,
  PopupType.Suggestions,
  PopupType.PayLater,
  PopupType.PayNowOrLater,
  PopupType.Cart,
];

const noHistoryPopups = [
  PopupType.None,
  PopupType.CashbackRegister,
  PopupType.Welcome,
  PopupType.VerificationEmail,
];

export const usePopupNavigation = () => {
  const {
    dispatch: appDispatch,
  } = useContext(AppContext);
  const { popup, dispatch } = useContext(PopupContext);

  const { isLogged } = useContext(AuthContext);

  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const modal = document.querySelector("#modal");
  const modalContainer = document.querySelector("#modalContainer");

  const goToPopup = async (current: PopupType, params: PopupParams = null) => {
    const isRegisterAndLogged = isLogged && current === PopupType.Register;
    const isAlreadyOpen = popup.current === current && isEqual(popup.params, params);

    if (isAlreadyOpen || isRegisterAndLogged) return;

    const history = noHistoryPopups.includes(popup.current) ? [...popup.history] : [...popup.history, {popup: popup.current, params: popup.params}];
    const keepOpen = keepOpenPopups.includes(current);
    const ms = popup.current === PopupType.None || keepOpen ? 0 : ANIMATION_DURATION;

    popup.current !== PopupType.None && !keepOpen && modal?.classList.remove("show-modal");

    await wait(ms);

    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current,
        params,
        history,
      },
    });
  };

  const goBackPopup = async () => {
    const history = [...popup.history];
    let lastPopup = history.pop() || {popup: PopupType.None, params: null};

    if (isLogged && lastPopup?.popup === PopupType.Register) {
      lastPopup = history.pop() || {popup: PopupType.None, params: null};
    }

    const { popup: current, params } = lastPopup;
    const keepOpen = keepOpenPopups.includes(popup.current);
    const ms = popup.current === PopupType.None || keepOpen ? 0 : ANIMATION_DURATION;

    !keepOpen && modal?.classList.remove("show-modal");

    await wait(ms);
  
    if (popup.current === PopupType.TipsSmall) {
      appDispatch({
        type: 'UPDATE_TIP',
        payload: {
          selected: "",
          amount: 0,
        }
      });
    }
  
    if (popup.current === PopupType.PaymentDetail && current !== PopupType.PaymentMethods) {
      appDispatch({
        type: 'UPDATE_CHECKOUT',
        payload: 
            {
              loyaltyDiscountCents: 0,
            }
      })
    }
  
    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current,
        params,
        history,
      },
    });
  };

  const closePopup = async () => {
    modal?.classList.remove("show-modal");
    modalContainer?.classList.remove("modal-in");

    await wait(500);
  
    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current: PopupType.None,
        params: null,
        history: [],
      },
    });
  };

  const memorizeHistory = () => {
    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        memory: [...popup.history, {popup: popup.current, params: popup.params}],
      },
    });
  }

  const goToMemory = async () => {
    const history = [...popup.memory];
    let lastPopup = history.pop() || {popup: PopupType.None, params: null};

    if (isLogged && lastPopup?.popup === PopupType.Register) {
      lastPopup = history.pop() || {popup: PopupType.None, params: null};
    }

    const { popup: current, params } = lastPopup;
    const keepOpen = keepOpenPopups.includes(current)
    const ms = popup.current === PopupType.None || keepOpen ? 0 : ANIMATION_DURATION;
    
    !keepOpen && modal?.classList.remove("show-modal");
    
    await wait(ms);

    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current,
        params,
        history,
        memory: [],
      },
    });
  };
  
  return { goToPopup, goBackPopup, closePopup, memorizeHistory, goToMemory };
};