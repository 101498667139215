import React from 'react';
import { TextInput } from '../inputs';
import { Button } from '../buttons';
import { t } from 'i18next';
import { CodeCouponInputContainer } from './PaymentDetailContainers';
import { Icon, IconType } from '../icons';

export const CodeCouponInput = ({
  codeCouponCents,
  codeCouponDeleteClicked,
  codeCouponText,
  handleCouponChange,
  codeCouponError,
  redeemingCodeCoupon,
  disabledCouponField,
  codeCouponApplyClicked,
}: any) => {
  return (
    <CodeCouponInputContainer>
      <h3>
        <Icon type={IconType.Tag} size={22}/>
        {t('do_you_have_a_code_coupon')}
      </h3>
      <div>
        {codeCouponCents <= 0 &&
          <>
            <TextInput
              value={codeCouponText}
              onChange={handleCouponChange}
              error={codeCouponError}
              disabled={redeemingCodeCoupon || disabledCouponField}
            />
            <Button
              title={t('Apply')}
              disabled={disabledCouponField}
              handleClick={codeCouponApplyClicked}
              loading={redeemingCodeCoupon}
            />
          </>
        }
        {codeCouponCents > 0 &&
          <>
            <div className='fake_input'>
              <span>
                {codeCouponText}
                <button onClick={codeCouponDeleteClicked}>
                  <Icon type={IconType.Close} size={12}/>
                </button>
              </span>
            </div>
            <p>{t('Applied!')} 🎉</p>
          </>
        }
      </div>
    </CodeCouponInputContainer>
  );
};
